(function($) {

    'use strict';

    function FilterProducts() {

        var self = this,
            $el = null,
            $filterlinks,
            $line,
            nbrItems,
            itemWidth,
            $formPaging = null,
            $formPagingInput = null,
            options = {
                example: null,
            };

        var $filtersections = $('.section__grid_filter');

        function onResize() {
            // Example
            if (window.bp.getLastValue() !== window.bp.getValue()) {
                if (window.bp.getValue() === 'xl') {
                    console.log('header, xl');
                }

                if (window.bp.getValue() === 'lg') {
                    console.log('header, lg');
                }

                if (window.bp.getValue() === 'md') {
                    console.log('header, md');
                }

                if (window.bp.getValue() === 'sm') {
                    console.log('header, sm');
                }

                if (window.bp.getValue() === 'xs') {
                    console.log('header, xs');
                }
            }
        }

        // Submenu Tab Technologie & scroll anchor on mobile
        function showTabsCircle(e){
            console.dir('showTabsCircle');
            e.preventDefault();
            var itemPos = e.target.dataset.position;
            var itemLength = 100 / e.target.parentElement.children.length;
            var $parent = $(e.target).parents('.section__grid_filter');
            $parent.find('.filter_btn.js-active-filter').removeClass('js-active-filter');
            // $parent.find('.tab_content.js-active-content').removeClass('js-active-content');
            $(e.target).addClass('js-active-filter');
            $parent.find('.filter__header').stop().animate({scrollLeft: e.target.offsetLeft}, 800);
            // $parent.find('[data-tabcontent="'+e.target.dataset.tab+'_content"]').addClass('js-active-content');
            $parent.find('.progress').css('margin-left', (itemLength * itemPos) + '%');
        }

        // Load post from next page
        function changePage(e){
            e.preventDefault();
            
            var currentPage = $formPaging.data('url');
            $formPaging.attr('action', currentPage + '/page/' + $formPagingInput.val());
            $formPaging.unbind('submit').submit();
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('filter products');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            $filtersections.each(function( index, el ) {
                $line = $(el).find('.progress');
                $filterlinks = $(el).find('.filter_btn');
                nbrItems = $filterlinks.length;
                itemWidth = 100 / nbrItems;
                $line.css('width', itemWidth + '%');
                $filterlinks.css('max-width', itemWidth + '%');
                $filterlinks.on('click', showTabsCircle);
            });

            $formPaging = $('#form_shop_paging');
            $formPagingInput = $('#form_shop_paging_input');
            $formPaging.on('submit', changePage);

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.FilterProducts = FilterProducts;

}(jQuery));
