(function($) {

    'use strict';

    function Accordion() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var $accordions = $('.acc__item_title');

        function onResize() {
            // Example
            if (window.bp.getLastValue() !== window.bp.getValue()) {
                if (window.bp.getValue() === 'xl') {
                    console.log('header, xl');
                }

                if (window.bp.getValue() === 'lg') {
                    console.log('header, lg');
                }

                if (window.bp.getValue() === 'md') {
                    console.log('header, md');
                }

                if (window.bp.getValue() === 'sm') {
                    console.log('header, sm');
                }

                if (window.bp.getValue() === 'xs') {
                    console.log('header, xs');
                }
            }
        }

        function toggleAccordion(e){
            console.dir('toggleAccordion');
            e.preventDefault();
            if (!e.target.classList.contains('js-toggle-acc')) {
                e.target.classList.add('js-toggle-acc');
            } else {
                e.target.classList.remove('js-toggle-acc');
            }
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('accordion');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            $accordions.on('click', toggleAccordion);
        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Accordion = Accordion;

}(jQuery));
