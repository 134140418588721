(function($) {

    'use strict';

    function Detaillant() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var select = document.getElementById('country__filter');
        var distributors = document.querySelectorAll('.item_distributeur');
    
        function onResize() {
            // Example
            if (window.bp.getLastValue() !== window.bp.getValue()) {
                if (window.bp.getValue() === 'xl') {
                    console.log('header, xl');
                }

                if (window.bp.getValue() === 'lg') {
                    console.log('header, lg');
                }

                if (window.bp.getValue() === 'md') {
                    console.log('header, md');
                }

                if (window.bp.getValue() === 'sm') {
                    console.log('header, sm');
                }

                if (window.bp.getValue() === 'xs') {
                    console.log('header, xs');
                }
            }
        }

        // Filter distributors by country
        function showDistributors(e){
            e.preventDefault();
            $('.item_distributeur').removeClass('js-show');
            if (e.target.value !== 'all') {
                var selected = document.querySelectorAll('[data-country='+e.target.value+']');
                for (var i = 0; i < selected.length; i++) {
                    selected[i].classList.add('js-show');
                }
            } else {
                $('.item_distributeur').addClass('js-show');
            }
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('detaillant');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            if (distributors.length > 0) {
                select.addEventListener('change', showDistributors);
            }

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Detaillant = Detaillant;

}(jQuery));
