/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Breakpoint
        window.bp = new Breakpoint();

        window.cache = {
          $window: $(window),
          $document: $(document),
          $body: $('body'),
        };


        // Modernizr example
        // if (!Modernizr.touchevents) {
        //   console.log('notouch');
        // } else {
        //   console.log('touch');
        // }

        //---------
        // Header
        //---------
        var $header = $('[data-module="header"]');
        if ($header.length > 0) {
          new Header({
            $el: $header
          });
        }
        //---------
        // Tabs
        //---------
        var $tabs = $('[data-module="tabs"]');
        if ($tabs.length > 0) {
          new Tabs({
            $el: $tabs
          });
        }
        //---------
        // Sliders
        //---------
        var $slider = $('[data-module="slider"]');
        if ($slider.length > 0) {
          new Slider({
            $el: $slider
          });
        }
        //---------
        // Videos
        //---------
        var $video = $('[data-module="video"]');
        if ($video.length > 0) {
          new Video({
            $el: $video
          });
        }
        //---------
        // Accordions
        //---------
        var $accordion = $('[data-module="accordion"]');
        if ($accordion.length > 0) {
          new Accordion({
            $el: $accordion
          });
        }
        //---------
        // Sidebar
        //---------
        var $sidebar = $('[data-module="sidebar"]');
        if ($sidebar.length > 0) {
          new Sidebar({
            $el: $sidebar
          });
        }
        //---------
        // Scroll Indicator
        //---------
        var $scroll_indicator = $('[data-module="scroll_indicator"]');
        if ($scroll_indicator.length > 0) {
          new ScrollIndicator({
            $el: $scroll_indicator
          });
        }
        //---------
        // Filter
        //---------
        var $filter = $('[data-module="filter"]');
        if ($filter.length > 0) {
          new Filter({
            $el: $filter
          });
        }
        //---------
        // Banner
        //---------
        var $banner = $('[data-module="banner"]');
        if ($banner.length > 0) {
          new Banner({
            $el: $banner
          });
        }
        //---------
        // Reveal
        //---------
        var $reveal = $('[data-module="reveal"]');
        if ($reveal.length > 0) {
          new Reveal({
            $el: $reveal
          });
        }
        //---------
        // Upload
        //---------
        var $upload = $('[data-module="upload"]');
        if ($upload.length > 0) {
          new Upload({
            $el: $upload
          });
        }
        //---------
        // Filter Products
        //---------
        var $filterprod = $('[data-module="filterprod"]');
        if ($filterprod.length > 0) {
          new FilterProducts({
            $el: $filterprod
          });
        }
        //---------
        // Trouver un detaillant
        //---------
        var $detaillant = $('[data-module="detaillant"]');
        if ($detaillant.length > 0) {
          new Detaillant({
            $el: $detaillant
          });
        }

        //---------
        // ScrollMagic
        //---------
        var $scroll = $('[data-module="scroll"]');
        if ($scroll.length > 0) {
          new Scroll({
            $el: $scroll
          });
        }

        //---------
        // Contact page (example)
        //---------
        // var $contact = $('[data-module="contact"]');
        // if ($contact.length > 0) {
        //   new Contact({
        //     $el: $contact
        //   });
        // }



        //---------
        // Activate link on division
        //---------
        $('.js-block-link').on('click', function(e) {
          e.preventDefault();

          window.location = $(this).find('a').attr('href');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;

      funcname = (funcname === undefined) ? 'init' : funcname;
      
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
