(function($) {

    'use strict';

    function Banner() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        // Timeline Animation
        // var anim = new TimelineMax({ paused:true, clearProps:'all' });
        // var elements = $('.section__hero_top .hero__cta > *');
        // var speed = 0.4;
        // var delay = 0.2;

        // anim.staggerFromTo(elements, speed,
        //     {autoAlpha:0, x:-20, ease:Power3.easeInOut},
        //     {autoAlpha:1, x:0, ease:Power3.easeInOut},
        // delay);

        function onResize() {
            // Example
            // if (window.bp.getLastValue() !== window.bp.getValue()) {
            //     if (window.bp.getValue() === 'xl') {
            //         console.log('header, xl');
            //     }

            //     if (window.bp.getValue() === 'lg') {
            //         console.log('header, lg');
            //     }

            //     if (window.bp.getValue() === 'md') {
            //         console.log('header, md');
            //     }

            //     if (window.bp.getValue() === 'sm') {
            //         console.log('header, sm');
            //     }

            //     if (window.bp.getValue() === 'xs') {
            //         console.log('header, xs');
            //     }
            // }
        }

        function startAnimation(){
            TweenLite.defaultOverwrite = false;
            anim.restart();
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            // console.log('banner');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            // window.addEventListener('load', startAnimation);

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Banner = Banner;

}(jQuery));
