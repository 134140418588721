(function($) {

    'use strict';

    function Upload() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };
        
        function onResize() {
            // Example
            if (window.bp.getLastValue() !== window.bp.getValue()) {
                if (window.bp.getValue() === 'xl') {
                    console.log('header, xl');
                }

                if (window.bp.getValue() === 'lg') {
                    console.log('header, lg');
                }

                if (window.bp.getValue() === 'md') {
                    console.log('header, md');
                }

                if (window.bp.getValue() === 'sm') {
                    console.log('header, sm');
                }

                if (window.bp.getValue() === 'xs') {
                    console.log('header, xs');
                }
            }
        }

        // Get filename
        function getFileName(e) {
            var fullPath = document.querySelector('.upload-btn-wrapper input');
            var fileTextfield = document.getElementById('file-name');
            if (fullPath) {
                var input = event.srcElement;
                var fileName = input.files[0].name;
                fileTextfield.textContent = fileName;
            }
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('upload');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            $('.upload-btn-wrapper').on('change', getFileName);

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Upload = Upload;

}(jQuery));
