/* jshint ignore:start */

/**
 * Utils
*/

// namespace
window.Utils = window.Utils || {};

(function($) {

  'use strict';

  Utils.debounce = function(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  /**
  * URI parser
  * @param {String} Uri that has to be parsed
  * @returns {object} Contain url parts
  */
  Utils.parseURL = function(url) {
    var key = ['source','protocol','authority','userInfo','user','password','host','port','relative','path','directory','file','query', 'hash'];
    var query   = {name:'params', parser: /(?:^|&)([^&=]*)=?([^&]*)/g};
    var parser = /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/;
    var match   = parser.exec(url.toString());

    var uri = {};
    var index   = key.length;
    while (index--) uri[key[index]] = match[index] || '';
    
    uri[query.name] = {};
    uri[key[12]].replace(query.parser, function (params, id, value) {if (id) uri[query.name][id] = value;});

    return {
      uri: uri,
      source: uri.source,
      protocol: uri.protocol,
      domain: uri.host,
      host: uri.protocol+'://' + uri.host,
      path: uri.path,
      fullpath: uri.protocol+'://' + uri.host + (uri.port ? ':' + uri.port:'') + uri.path,
      query: uri.params,
      queryString: uri.query,
      hash: uri.hash,
      directory: uri.directory,
      file: uri.file
    };
  };

  /**
   * Set equal height for all elements passed in parameters
   * @param {jQuery list} $col
   */
  Utils.setEqualHeight = function($col) {
    // Find max height
    var maxHeight = 0;
    $col.each(function() {
      var $this = $(this);
      $this.removeAttr('style');              // Remove previously set height for responsive behavior

      var $height = $this.innerHeight();

      if ($height > maxHeight) {
        maxHeight = $height;
      }
    });

    // Set height
    $col.each(function() {
      $(this).css('height', maxHeight);
    });
  };

  /**
   * Remove equal height for all elements passed in parameters
   * @param {jQuery list} $col
   */
  Utils.removeEqualHeight = function($col) {
    $col.each(function() {
      $(this).removeAttr('style');
    });
  };

  /**
   * http://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-get-parameters
   * @param  {String} query
   * @return {Object}
   */
  Utils.getQueryString = function(string) {
    var query_string = {},
      query = string.substring(1),
      vars = query.split('&');

    for (var i = 0, length = vars.length; i < length; i++) {
      var pair = vars[i].split('=');
        // If first entry with this name
      if (typeof query_string[pair[0]] === 'undefined') {
        query_string[pair[0]] = decodeURIComponent(pair[1]);
      // If second entry with this name
      } else if (typeof query_string[pair[0]] === 'string') {
        var arr = [ query_string[pair[0]],decodeURIComponent(pair[1]) ];
        query_string[pair[0]] = arr;
      // If third or later entry with this name
      } else {
        query_string[pair[0]].push(decodeURIComponent(pair[1]));
      }
    }
    return query_string;
  };

  Utils.getIEVersion = function() {
    var agent = navigator.userAgent;
    var reg = /MSIE\s?(\d+)(?:\.(\d+))?/i;
    var matches = agent.match(reg);
    if (matches != null) {
      return { major: matches[1], minor: matches[2] };
    }
    return { major: "-1", minor: "-1" };
  };

  Utils.isIE = function() {
    return (/MSIE/i.test(navigator.userAgent)) || (/Trident.*rv\:11\./i.test(navigator.userAgent));
  };

  Utils.isEdge = function() {
    return (/Edge/i.test(navigator.userAgent));
  };

  Utils.isFirefox = function() {
    return (/Firefox/i.test(navigator.userAgent));
  };

  Utils.validateEmail = function(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(mail)) {
        return (true);
    }

    return (false);
  };

  /**
   * Scrollto a section
   * @param  {jQuery Object} $item 
   * @param  {Number or String} speed  [Optional default to 800]
   * @param  {Number} offset [Optional]
   */
  Utils.scrollTo = function($item, pSpeed, offset) {
    var scrollTop = $item.offset().top,
        speed = pSpeed || 800;

    if (offset) {
      scrollTop += offset;
    }

    $('html, body').stop().animate({ scrollTop: scrollTop }, speed);
  };


  /**
   * Get which transitionEnd event is used by browser
   * @return {String} Event name
   */
  Utils.whichTransitionEvent = function() {
    // Function from David Walsh: http://davidwalsh.name/css-animation-callback
    var t,
        el = document.createElement('fakeelement');

    var transitions = {
      'transition'      : 'transitionend',
      'OTransition'     : 'oTransitionEnd',
      'MozTransition'   : 'transitionend',
      'WebkitTransition': 'webkitTransitionEnd'
    };

    for (t in transitions){
      if (el.style[t] !== undefined){
        return transitions[t];
      }
    }
  };

  /**
   * Slugify a string
   * @param  {String} string
   * @return {String}
   */
  Utils.slugify = function(string) {
    var a = 'àáäâãèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;'
    var b = 'aaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------'
    var p = new RegExp(a.split('').join('|'), 'g')

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        // .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
  };

  /**
   * Check if number is an integer
   * @param  {[type]}  n [description]
   * @return {Boolean}   [description]
   */
  Utils.isInt = function(n) {
    return n % 1 === 0;
  }

}(jQuery));
