(function($) {

    'use strict';

    function Reveal() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var rafId = null;
        var delay = 200;
        var lTime = 0;

        function onResize() {
            // Example
            if (window.bp.getLastValue() !== window.bp.getValue()) {
                if (window.bp.getValue() === 'xl') {
                    console.log('header, xl');
                }

                if (window.bp.getValue() === 'lg') {
                    console.log('header, lg');
                }

                if (window.bp.getValue() === 'md') {
                    console.log('header, md');
                }

                if (window.bp.getValue() === 'sm') {
                    console.log('header, sm');
                }

                if (window.bp.getValue() === 'xs') {
                    console.log('header, xs');
                }
            }
        }

        function reveal() {
            rafId = null;
            var now = performance.now();
            if (now - lTime > delay) {
                lTime = now;
                var $ts = $('.reveal_pending');
                $($ts.get(0)).removeClass('reveal_pending').addClass('reveal_visible');  
            }
            if ($('.reveal_pending').length >= 1) { rafId = requestAnimationFrame(reveal); }
        }

        function scroll() {
            var scrollTop = $(window).scrollTop();
            var height = $(window).height();
            var visibleTop = scrollTop + height;
            $('.reveal').each(function() {
                var $t = $(this);
                if ($t.hasClass('reveal_visible')) { return; }
                var top = $t.offset().top;
                if (top <= visibleTop) {
                    if (top + $t.height() < scrollTop) {
                        $t.removeClass('reveal_pending').addClass('reveal_visible');
                    } else {
                        $t.addClass('reveal_pending');
                        if (!rafId) { requestAnimationFrame(reveal); }  
                    }
                }
            });
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('reveal');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            // $(scroll);
            // $(window).scroll(scroll);

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Reveal = Reveal;

}(jQuery));
