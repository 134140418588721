(function($) {

    'use strict';

    function Video() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var done = false;

        self.initialize = function(opts){
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('video');
            // Caching
            $el = options.$el;

            var players = [];
            var p = {};

            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            
            function onPlayerReady(event){
                console.dir(event.target.h);
                event.target.h.classList.add('js-loaded');
            }

            // when video is playing
            function onPlayerStateChange(event){
                console.log(event.data);
                // End
                if(event.data === 0) {
                    event.target.h.classList.remove('js-hide-thumb');
                }
                // Start
                if(event.data === 1) {
                    event.target.h.classList.add('js-hide-thumb');
                }
            }

            // when click on play button
            function playVid(e){
                console.dir(players);
                var p, btn;
                for (var x = 0; x < players.length; x++) {
                    p = players[x].h.id;
                    btn = e.target.dataset.player;
                    if (p === btn) {
                        players[x].playVideo();
                    }
                }
            }

            // initialize iframe youtube
            function onYouTubeIframeAPIReady(){
                $('.video-image').each(function(i, el){
                    var $id = $(this).data('id');
                    var $play = $(this).find('.play-btn');
                    var t = new YT.Player('player_'+$id, {
                        videoId: $id,
                        events: {
                            'onStateChange': onPlayerStateChange,
                            'onReady': onPlayerReady
                        }
                    });
                    players.push(t);
                    $play.on('click', playVid);
                });
            }

            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Video = Video;

}(jQuery));
