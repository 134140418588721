(function($) {

    'use strict';

    function Scroll() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var rafId = null;
        var delay = 200;
        var lTime = 0;

        self.$cache      = {};
        var animationScrollEvent;

        function onResize() {
            // Example
            // if (window.bp.getLastValue() !== window.bp.getValue()) {
            //     if (window.bp.getValue() === 'xl') {
            //         console.log('header, xl');
            //     }

            //     if (window.bp.getValue() === 'lg') {
            //         console.log('header, lg');
            //     }

            //     if (window.bp.getValue() === 'md') {
            //         console.log('header, md');
            //     }

            //     if (window.bp.getValue() === 'sm') {
            //         console.log('header, sm');
            //     }

            //     if (window.bp.getValue() === 'xs') {
            //         console.log('header, xs');
            //     }
            // }
        }

        function initCache() {
            self.$cache.window      = $(window);
            self.$cache.html        = $('html');
            self.$cache.sections    = self.$cache.html.find('.section__tween');
        }

        function animationScroll() {
            
            console.dir('scroll');

            var ctrl      = new ScrollMagic.Controller();

            self.$cache.sections.each(function(index, element) {

                var tl        = new TimelineMax();
                var tweenX    = $(element).find('.tweenX');
                var tweenY    = $(element).find('.tweenY');

                if (tweenX) {
                    tl.staggerFromTo(tweenX, 0.8,
                        {autoAlpha:0, x:-20, ease:Power3.easeInOut},
                        {autoAlpha:1, x:0, ease:Power3.easeInOut},
                    0.2);
                }

                if (tweenY) {
                    tl.staggerFromTo(tweenY, 0.8,
                        {autoAlpha:0, y:-20, ease:Power3.easeInOut},
                        {autoAlpha:1, y:0, ease:Power3.easeInOut},
                    0.2);
                }

                new ScrollMagic.Scene({
                    triggerElement: this,
                    reverse: false,
                    offset: -200
                })
                .setTween(tl)
                // .addIndicators()
                .addTo(ctrl);

            });
        }

        function initEventsConst() {
            animationScrollEvent = function(e){
                animationScroll(e);
            };
        }

        function registerEvents() {
            self.$cache.window.on('load', animationScrollEvent);
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            initCache();
            initEventsConst();
            registerEvents();

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Scroll = Scroll;

}(jQuery));
