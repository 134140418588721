(function($) {

    'use strict';

    function Tabs() {

        var self = this,
            $el = null,
            $tablinks,
            $line,
            nbrItems,
            itemWidth,
            options = {
                example: null,
            };

        var $tabsections = $('.section__cta_tab_circle');

        function onResize() {
            // Example
            // if (window.bp.getLastValue() !== window.bp.getValue()) {
            //     if (window.bp.getValue() === 'xl') {
            //         console.log('header, xl');
            //     }

            //     if (window.bp.getValue() === 'lg') {
            //         console.log('header, lg');
            //     }

            //     if (window.bp.getValue() === 'md') {
            //         console.log('header, md');
            //     }

            //     if (window.bp.getValue() === 'sm') {
            //         console.log('header, sm');
            //     }

            //     if (window.bp.getValue() === 'xs') {
            //         console.log('header, xs');
            //     }
            // }
        }

        // Submenu Tab Technologie & scroll anchor on mobile
        function showTabsCircle(e){
            console.dir('showTabsCircle');
            e.preventDefault();
            var itemPos = e.target.dataset.position;
            var itemLength = 100 / e.target.parentElement.children.length;
            var $parent = $(e.target).parents('.section__cta_tab_circle');
            $parent.find('.tab_btn.js-active-tab').removeClass('js-active-tab');
            $parent.find('.tab_content.js-active-content').removeClass('js-active-content');
            $(e.target).addClass('js-active-tab');
            $parent.find('.tab__header').stop().animate({scrollLeft: e.target.offsetLeft}, 800);
            $parent.find('[data-tabcontent="'+e.target.dataset.tab+'_content"]').addClass('js-active-content');
            $parent.find('.progress').css('margin-left', (itemLength * itemPos) + '%');
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            $tabsections.each(function( index, el ) {
                $line = $(el).find('.progress');
                $tablinks = $(el).find('.tab_btn');
                nbrItems = $tablinks.length;
                itemWidth = 100 / nbrItems;
                $line.css('width', itemWidth + '%');
                $tablinks.css('max-width', itemWidth + '%');
                $tablinks.on('click', showTabsCircle);
            });

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Tabs = Tabs;

}(jQuery));
