(function($) {

  'use strict';

  function Contact() {

    var self = this,
      $form = null,
      options = {
        'example': null
      };

    /**
     * Generic resize 
     */
    function onResize() {
    }

    /**
     * Public constructor
     * @param  {Object} opts Options
     */
    self.initialize = function(opts) {
      //extends options
      for (var i in opts) { options[i] = opts[i]; }

      // Caching
      

      // Pour traduire les messages d'erreur de jQuery Validate, ajouter ces ligne en dessous du footer de base.php
      // <?php if (ICL_LANGUAGE_CODE == 'fr'): ?>
      //   <script src="<?php echo get_template_directory_uri(); ?>/assets/scripts/imports/messages_<?php echo ICL_LANGUAGE_CODE; ?>.min.js"></script>
      // <?php endif ?>


      // new GoogleMap({
      //     id: 'contact-map',
      // });

      //*************
      window.cache.$window.on('resize', Utils.debounce(onResize, 400));
      onResize();     // initial call on load
    };

    self.initialize.apply(self, arguments);
    return self;
  }

  /* Namespace declaration */
  window.Contact = Contact;

}(jQuery));