(function($) {

    'use strict';

    function Header() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var burger = document.getElementById("burger");
        var navmobile = document.getElementById("nav-mobile");
        var submenu = document.getElementById("header__submenu");
        var overlay = document.getElementById("overlay-mobile");
        var submobile = document.querySelectorAll("#nav-mobile .menu-item-has-children > a");
        var menusearch = document.getElementById("header__search_btn");
        var inputsearch = document.getElementById("search__input_header");
        var tablinks = document.querySelectorAll('.tech_tab');
        var mainlinks = document.querySelectorAll('#main_nav .menu-item');
        var toplinks = document.querySelectorAll('#top_nav .menu-item-has-children');

        var buttonSub       = document.querySelectorAll('nav li');
        var otherBtn        = document.querySelectorAll('nav a');
        var wrapper         = document.querySelector('.header__submenu_wrapper');
        var wrapperdivs     = document.querySelectorAll('.sub__section .column');
        var $html   = $('html');
        var $subcontainer   = $('.sub__container');
        var $subcontainerBg = $subcontainer.find('.sub__container-bg');
        var $subcontainerInner = $subcontainer.find('.sub__container-inner');
        var menuOpen        = false;
        var $menuHeight;
        var timer;
        var i;

        /**
         * Generic resize
         */
        function onResize() {
            // Example
//             if (window.bp.getLastValue() !== window.bp.getValue()) {
//                 if (window.bp.getValue() === 'xl') {
//                     console.log('header, xl');
//                 }
//
//                 if (window.bp.getValue() === 'lg') {
//                     console.log('header, lg');
//                 }
//
//                 if (window.bp.getValue() === 'md') {
//                     console.log('header, md');
//                 }
//
//                 if (window.bp.getValue() === 'sm') {
//                     console.log('header, sm');
//                 }
//
//                 if (window.bp.getValue() === 'xs') {
//                     console.log('header, xs');
//                 }
//             }
        }

        // Sticky Header on scroll
        function stickyHeader(){
            var html    = document.querySelector("html");
            var header  = document.getElementById("nav-header");
            var sticky = header.offsetTop + 95;
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        }

        // Open Mobile Menu
        function menuMobile(e){
            console.dir('menuMobile');
            e.preventDefault();
            if (!e.target.classList.contains('js-open-burger')) {
                document.body.classList.add('js-freeze');
                e.target.classList.add('js-open-burger');
                navmobile.classList.add('js-open-menu');
            } else {
                document.body.classList.remove('js-freeze');
                e.target.classList.remove('js-open-burger');
                navmobile.classList.remove('js-open-menu');
            }
        }

        // Add active class to mobile menu elements
        function activeMenu() {
            var url = window.location.pathname,
            urlRegExp = new RegExp(url.replace(/\/$/,'') + "$");
            $('#main_nav_mobile li a').each(function(){ // Swap to your menu CSS Class or ID
                if(urlRegExp.test(this.href.replace(/\/$/,''))){
                    $(this).addClass('current'); // Add CSS Class 'current' to active link tag
                }
            });
        }

        // Toggle Submenu Mobile
        function submenuMobile(e){
            console.dir('submenuMobile');
            e.preventDefault();
            e.target.classList.toggle('js-toggle-sub');
        }

        // Show search Input in Desktop Menu
        function showSearchInput(e){
            console.dir('showSearchInput');
            e.preventDefault();
            if (!e.target.classList.contains('js-toggle-search')) {
                e.target.classList.add('js-toggle-search');
                inputsearch.focus();
            } else {
                e.target.classList.remove('js-toggle-search');
                inputsearch.blur();
            }
        }

        // Submenu Tab Technologie
        function showTabsContent(e){
            console.dir('showTabsContent');
            $('.tech_tab.js-active-tab').removeClass('js-active-tab');
            $('.tech_content.js-active-content').removeClass('js-active-content');
            $(e.target).addClass('js-active-tab');
            $('#'+e.target.id+'_content').addClass('js-active-content');
        }

        function clickMainItem(e){
            e.preventDefault();
            window.location = e.target.firstElementChild.href;
        }

        // Show Sub Menu
        // function showSubmenu(e){
        //     e.preventDefault();
        //     console.dir('showSubmenu');
        //     var section = e.target.className.split(" ")[0];
        //     $(submenu).addClass('js-show-submenu');
        //     $('.menu-item.js-active-menu').removeClass('js-active-menu');
        //     $(e.target).addClass('js-active-menu');
        //     $('.sub__section.js-active-sub').removeClass('js-active-sub');
        //     $('.sub__section[data-sub='+section+']').addClass('js-active-sub');
        // }

        // // Hide Sub Menu
        // function hideSubmenu(e){
        //     console.dir('hideSubmenu');
        //     e.preventDefault();
        //     $(submenu).removeClass('js-show-submenu');
        //     $('.menu-item.js-active-menu').removeClass('js-active-menu');
        // }

        // function zindexIn() {
        //     console.dir('over');
        //     submenu.classList.add('js-zindex');
        // }

        // function zindexOut() {
        //     console.dir('out');
        //     submenu.classList.remove('js-zindex');
        // }

        function clearTimer() {
            clearTimeout(timer);
        }

        function closeSubMenu() {
            clearTimeout(timer);
            // console.log('close 1');
            if(!menuOpen) { return false; }

            timer = setTimeout(function() {
                var tl = new TimelineMax({onStart: function() {
                    console.log('close 2');
                    menuOpen = false;
                    $(buttonSub).removeClass('js-active-menu');
                    $html.removeClass('scroll-disabled');
                }, onComplete: function() {
                    $subcontainer.hide();
                }});
                tl.to('.sub__section.js-active-sub .column', 0.2, {autoAlpha: 0, ease: Power3.easeInOut}, 0);
                tl.to($subcontainerInner, 0.4, {height: 0, ease: Expo.easeInOut}, 0);
                tl.to($subcontainerBg, 0.4, {opacity: 0, ease: Expo.easeInOut}, 0);
            }, 250);
        }

        function openSubMenu(e) {
            var section = e.target.className.split(" ")[0];
            var $sub = $('.sub__section[data-sub='+section+']');

            if (section !== 'menu-item' && $sub.length > 0) {
                console.log('timer');
                clearTimeout(timer);
                timer = setTimeout(function() {
                    menuOpen = true;

                    $(buttonSub).removeClass('js-active-menu');
                    $(e.target).addClass('js-active-menu');

                    $('.sub__section.js-active-sub').removeClass('js-active-sub');
                    $('.sub__section[data-sub='+section+']').addClass('js-active-sub');

                    $subcontainer.show();

                    var $sub = $('.sub__section.js-active-sub');
                    var $subCol =  $sub.find('.column');
                    var menuHeight = $sub.height();

                    var tl = new TimelineMax({onStart: function() {
                        $html.addClass('scroll-disabled');
                    }});

                    tl.staggerFromTo('.sub__section.js-active-sub .column', 0.4, {autoAlpha:0, x:-10, ease:Power3.easeInOut}, {autoAlpha:1, x:0, ease:Power3.easeInOut}, 0.2);
                    tl.to($subcontainerInner, 0.4, {height: menuHeight, ease: Cubic.easeInOut}, 0);
                    tl.to($subcontainerBg, 0.6, {opacity: 1, ease: Cubic.easeOut}, 0);

                }, 250);
            } else {
                closeSubMenu();
            }
        }



        /**
         * Public constructor
         * @param  {Object} opts Options
         */
        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }

            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            // activeMenu();

            // submobile.forEach(function(item){
            //     item.addEventListener('click', submenuMobile);
            // });


            tablinks.forEach(function(link){
                link.addEventListener('mouseover', showTabsContent);
            });

            mainlinks.forEach(function(link){
                // link.addEventListener('mouseover', showSubmenu);
                link.addEventListener('click', clickMainItem);
            });

            for (var a = 0; a < submobile.length; a++) {
                submobile[a].addEventListener('click', submenuMobile);
            }

            for (var b = 0; b < tablinks.length; b++) {
                tablinks[b].addEventListener('mouseover', showTabsContent);
            }

            for (var c = 0; c < mainlinks.length; c++) {
            //     mainlinks[c].addEventListener('mouseover', showSubmenu);
                mainlinks[c].addEventListener('click', clickMainItem);
            }

            // for (var d = 0; d < toplinks.length; d++) {
                // toplinks[d].addEventListener('mouseenter', zindexIn);
                // toplinks[d].addEventListener('mouseleave', zindexOut);
            // }

            // submenu.addEventListener('mouseleave', hideSubmenu);

            stickyHeader();
            window.addEventListener('scroll', stickyHeader);
            burger.addEventListener('click', menuMobile);
            menusearch.addEventListener('click', showSearchInput);

            // Add wrapper around wysiwyg iframes
            $( ".wysiwyg iframe" ).wrap("<div class='iframe-container'></div>");

            for (i = 0; i < buttonSub.length; i++) {
                buttonSub[i].addEventListener('mouseenter', openSubMenu);
                buttonSub[i].addEventListener('mouseleave', closeSubMenu);
            }
            for (i = 0; i < otherBtn.length; i++) {
                otherBtn[i].addEventListener('mouseenter', closeSubMenu);
            }
            wrapper.addEventListener('mouseleave', closeSubMenu);

            $subcontainerInner.on('mouseenter', clearTimer);
            $subcontainerInner.on('mouseleave', closeSubMenu);

            // var mobile_search_input = document.getElementById('search__input_header_mobile');
            $('#search__input_header_mobile').on('focus', function() {
                console.log( "search input focused" );
                $(this).closest('.search__form_mobile').addClass('focused');
            });
            $('#search__input_header_mobile').on('blur', function() {
                console.log( "search input blur" );
                setTimeout(function() {
                    $('.search__form_mobile').removeClass('focused');
                }, 1000);
                // $(this).closest('.search__form_mobile').removeClass('focused');
            });

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Header = Header;

}(jQuery));
