(function($) {

    'use strict';

    function Filter() {

        var self = this,
            $el = null,
            $formPaging = null,
            $formPagingInput = null,
            options = {
                example: null,
            };

        function onResize() {
            // Example
            if (window.bp.getLastValue() !== window.bp.getValue()) {
                if (window.bp.getValue() === 'xl') {
                    console.log('header, xl');
                }

                if (window.bp.getValue() === 'lg') {
                    console.log('header, lg');
                }

                if (window.bp.getValue() === 'md') {
                    console.log('header, md');
                }

                if (window.bp.getValue() === 'sm') {
                    console.log('header, sm');
                }

                if (window.bp.getValue() === 'xs') {
                    console.log('header, xs');
                }
            }
        }

        // Load post from next page
        function loadMore(e){
            e.preventDefault();
            e.target.textContent = e.target.dataset.wait;
            var $next = $('.next.page-numbers').attr('href');
            var $bottomRight = $('.bottom__right.paginations');
            var $bottomMid = $('.bottom__mid');
            $.ajax({
                url: $next,
                dataType: 'html',
                type: 'POST',
                success: function(data){
                    var items = $(data).find('.section__grid_wrapper').html();
                    $('.section__grid_wrapper').append(items);
                    $('.bottom__right.paginations').html($bottomRight);
                    $('.bottom__mid').html($bottomMid);
                    e.target.textContent = e.target.dataset.load;
                },
            });
        }

        // Load post from next page
        function changePage(e){
            e.preventDefault();
            
            var currentPage = $formPaging.data('url');
            $formPaging.attr('action', currentPage + '/page/' + $formPagingInput.val());
            $formPaging.unbind('submit').submit();
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('filter');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            // $('#load-more').on('click', loadMore);

            $formPaging = $('#form_resources_paging');
            $formPagingInput = $('#form_resources_paging_input');
            $formPaging.on('submit', changePage);

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Filter = Filter;

}(jQuery));
