(function($) {

    'use strict';

    function Sidebar() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        var gap = 90;
        var menubar = 160;
        var containerScene = false;
        var controller = false;

        function onResize() {
            var contentLeft = $('.text__content_left').height();
            var sidebar     = $('#cta__sidebar').height();

            if (containerScene && controller) {
                containerScene.duration(contentLeft - sidebar);
                controller.updateScene(containerScene, true);
                // Example
                if (window.bp.getLastValue() !== window.bp.getValue()) {
                    if ((window.bp.getValue() === 'xl' || window.bp.getValue() === 'lg' || window.bp.getValue() === 'md') && (window.bp.getLastValue() === 'sm' || window.bp.getLastValue() === 'xs'|| window.bp.getLastValue() === null)) {
                      //Desktop
                      controller.addScene(containerScene);
                      containerScene.setPin('#cta__sidebar');
                      controller.update(true);
                    }
                    if ((window.bp.getValue() === 'sm' || window.bp.getValue() === 'xs') && (window.bp.getLastValue() === 'xl' || window.bp.getLastValue() === 'lg' || window.bp.getLastValue() === 'md' || window.bp.getLastValue() === null)) {
                      //Mobile
                      containerScene.removePin(true);
                      controller.removeScene(containerScene);
                      controller.update(true);
                    }
                }
            }
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            console.log('sidebar');
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', onResize);
            // Initial call on load
            onResize();

            var contentLeft = $('.text__content_left').height();
            var contentRight = $('.text__content_right');
            var sidebar     = $('#cta__sidebar').height();
            if (sidebar < 800) {
                $('.section__text_content').addClass('js-sidebar');
                controller = new ScrollMagic.Controller();
                containerScene = new ScrollMagic.Scene({
                    triggerElement: '.section__text_content',
                    duration: contentLeft - sidebar - 100,
                    offset: -100,
                    reverse: true,
                    triggerHook: 0,
                })
                .setPin('#cta__sidebar')
                .setClassToggle(containerScene, 'is-active')
                .addTo(controller);
                // .addIndicators({
                //     colorTrigger : "red",
                //     colorStart : "red",
                //     colorEnd : "red",
                // });
            }

        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Sidebar = Sidebar;

}(jQuery));
