(function($) {

    'use strict';

    function Slider() {

        var self = this,
            $el = null,
            options = {
                example: null,
            };

        function onResize() {
            // Example
            // if (window.bp.getLastValue() !== window.bp.getValue()) {
            //     if (window.bp.getValue() === 'xl') {
            //         console.log('header, xl');
            //     }

            //     if (window.bp.getValue() === 'lg') {
            //         console.log('header, lg');
            //     }

            //     if (window.bp.getValue() === 'md') {
            //         console.log('header, md');
            //     }

            //     if (window.bp.getValue() === 'sm') {
            //         console.log('header, sm');
            //     }

            //     if (window.bp.getValue() === 'xs') {
            //         console.log('header, xs');
            //     }
            // }
        }

        function sliderSingleProduct(sliderSingle, singleNav){
            $(sliderSingle).on('init', function(event, slick, direction){
                $('.single__product_top_right').addClass('js-init');
                if (slick.slideCount < 2) {
                    $(singleNav).addClass('js-hidden');
                }
            });
            $(sliderSingle).slick({
                dots: false,
                infinite: true,
                speed: 500,
                fade: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                autoplay: false,
                autoplaySpeed: 4000,
                asNavFor: singleNav,
            });
            $(singleNav).slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: sliderSingle,
                dots: false,
                focusOnSelect: true,
                vertical: true,
                prevArrow: "<svg class='prev' width='25px' height='48px' viewBox='0 0 25 48' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='arrow-left' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><polyline stroke='#0080CC' stroke-width='2' transform='translate(23.000000, 23.000000) scale(-1, 1) rotate(45.000000) translate(-23.000000, -23.000000)' points='7 7 39 7 39 39'></polyline></g></svg>",
                nextArrow: "<svg class='next' width='25px' height='48px' viewBox='0 0 25 48' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='CP_01_Accueil' transform='translate(-1758.000000, -3121.000000)' stroke='#0080CC' stroke-width='2'><g transform='translate(98.000000, 2713.000000)'><g id='arrow-right' transform='translate(1638.000000, 409.000000)'><polyline transform='translate(23.000000, 23.000000) rotate(45.000000) translate(-23.000000, -23.000000)' points='7 7 39 7 39 39'></polyline></g></g></g></g></svg>",
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            vertical: false,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            vertical: false,
                        }
                    }
                ]
            });
        }

        function slickSliderProducts(slider){
            $(slider).on('init', function(event, slick, direction){
                $(slider).addClass('js-init');
            });
            $(slider).slick({
                infinite: true,
                speed: 500,
                fade: false,
                arrows: true,
                dots: false,
                cssEase: 'ease-out',
                adaptiveHeight: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                prevArrow: "<svg class='prev' width='25px' height='48px' viewBox='0 0 25 48' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='arrow-left' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><polyline stroke='#0080CC' stroke-width='2' transform='translate(23.000000, 23.000000) scale(-1, 1) rotate(45.000000) translate(-23.000000, -23.000000)' points='7 7 39 7 39 39'></polyline></g></svg>",
                nextArrow: "<svg class='next' width='25px' height='48px' viewBox='0 0 25 48' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='CP_01_Accueil' transform='translate(-1758.000000, -3121.000000)' stroke='#0080CC' stroke-width='2'><g transform='translate(98.000000, 2713.000000)'><g id='arrow-right' transform='translate(1638.000000, 409.000000)'><polyline transform='translate(23.000000, 23.000000) rotate(45.000000) translate(-23.000000, -23.000000)' points='7 7 39 7 39 39'></polyline></g></g></g></g></svg>",
                responsive: [
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true,
                        }
                    }
                ]
            });
        }

        function slickSlider(slider){
            $(slider).on('init', function(event, slick, direction){
                console.dir(slick);
                if (slick.slideCount < 2) {
                    $(slider).find('.slick-dots').addClass('js-hidden');
                }
            });
            $(slider).slick({
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                adaptiveHeight: true,
            });
        }

        function slickSliderMobile(slider, dots, nbrSlides, padding){
            $(slider).on('init', function(event, slick, direction){
                console.dir(slick);
                if (slick.slideCount < 2) {
                    $(slider).find('.slick-dots').addClass('js-hidden');
                }
            });
            $(slider).slick({
                dots: dots,
                centerMode: true,
                centerPadding: padding,
                slidesToShow: nbrSlides,
                slidesToScroll: 1,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '40px',
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            arrows: false,
                            centerMode: true,
                            centerPadding: '20px',
                            slidesToShow: 1
                        }
                    }
                ]
            });
        }

        self.initialize = function(opts) {
            // extends options
            for (var i in opts) { options[i] = opts[i]; }
            // Caching
            $el = options.$el;

            // *************
            window.cache.$window.on('resize', Utils.debounce(onResize, 3000));
            // Initial call on load
            onResize();

            slickSlider('.cta__slider');
            slickSliderProducts('.cta__product_slider');
            slickSliderMobile('.cta__item_row_mobile', false, 3, '60px');
            slickSliderMobile('.quote__slider', true, 1, '25%');
            sliderSingleProduct('.product_image_main', '.product_image_thumbs');
            
        };

        self.initialize.apply(self, arguments);
        return self;
    }

  /* Namespace declaration */
  window.Slider = Slider;

}(jQuery));